.page_kosten {
  @include breakpoint(medium) {
    margin-top: 121px;

  }

  .image_top {
    background: url("../img/a_beeler_kosten_oben.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: cover;
    background-position: center center;
    height: 600px;
  }
  div.pages_content.container {
    padding-bottom: 140px;
  }
}