div#logo_navi {
  position: fixed;
  padding-left: 23px;
  top: 23px;
  @include breakpoint(medium) {
    padding-left: 25px;
    top: 25px;
  }
  z-index:9999;
  img {
    width:40px;
    @include breakpoint(medium) {
      width: 65px;
    }

    @include breakpoint(xlarge) {
      //width: 30%;
    }

    //width: 30%;
    height: auto;
    @include breakpoint(xlarge) {
      width: 77px;
    }
  }
}

//navigation


#logo_navi {
  display: none;
}

.main-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 0;
  padding: 20px;
  overflow: hidden;
  display: none;

  > div {
    text-align: center;
    padding: 0;
    position: relative;
    background-color: #333;
    backface-visibility: hidden;
    height: 20%;
    opacity: 0;

    > a {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 300;
    }
  }

  .navi_top {
    background: white;
    height: 40%;
    @include breakpoint(medium) {
      background: url("../img/8065_sw.jpg") no-repeat;
      background-size: cover;
      height: 60%;
    }

    .main {
      @include grid-row;
      padding-top: 3em;
      margin-bottom: 2em;
      @include breakpoint(medium) {
        padding-top: 5em;
        margin-bottom: 6em;
    }

      @include breakpoint(0 50vh) {
        padding-top: 5em;
        margin-bottom: 6em;
      }





      h2 {
        font-size: 1.5rem;
        @include grid-column;
        color: $beeler_brown;
        @include breakpoint(medium) {
          font-size: 1.8rem;
          color: white;
        }
        @include breakpoint(large) {
          font-size: 2rem;
        }



        &:hover {
          //color: $beeler_brown;
        }
      }

    }
    .sub {
      @include breakpoint(medium) {
        @include grid-column(4);
      }

      h2 {
        color: $beeler_brown;
        font-size: 1.1rem;
        @include breakpoint(medium) {
          color: white;
          font-size: 1rem;
        }
        @include breakpoint(large) {
          font-size: 1.5rem;
        }
        a {
          color: $beeler_brown;
          @include breakpoint(medium) {
            color: white;
          }
          &:hover {
            color: $beeler_brown;
          }
        }

      }
      ul {
        list-style: none;
        display: none;
        @include breakpoint(medium) {
          display: block;
        }
        li {
          a {
            color: $beeler_brown;
            @include breakpoint(medium) {
              color: white;

            }
            @include breakpoint(large) {
              color: white;
              font-size: 1.2rem;
              letter-spacing: 2px;
            }
            font-weight: bold;
            &:hover {
              color: $beeler_brown;
            }
          }
        }
      }
    }
  }

  .navi_bottom {
    height: 60%;
    @include breakpoint(medium) {
      height: 40%;
    }
    .kontakt {
      position: relative;
      width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
      background: $beeler_brown;
      height: 33.3333333%;
      @include breakpoint(medium) {
        @include grid-column(4);
        height: 100%;
        padding-right: 0!important;
        padding-left: 0!important;
      }


    }
    .ueber_mich {
      background: white;
      position: relative;
      width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
      height: 33.3333333%;
      @include breakpoint(medium) {
        height: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
        @include grid-column(4);

      }
      h2 {
        color: $beeler_brown;
        &:hover {
          color: $beeler_grey;
        }
      }

    }
    .kosten {
      position: relative;
      width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
      background: $beeler_brown;
      height: 33.3333333%;
      @include breakpoint(medium) {
        height: 100%;
        @include grid-column(4);
        padding-right: 0!important;
        padding-left: 0!important;
      }
    }
  }
}


.navi_bottom h2 {
  &:hover {
      color: $beeler_grey;
    }
  }

h2.navi_titel {
  color: $white;
  margin: 0;
  position: absolute;
  z-index: 200;
  width: 100%;
  text-align: center;
  top: 50%;

  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1.5rem;
  @include breakpoint(medium) {
    font-size: 1.8rem;
  }
  @include breakpoint(large) {
    font-size: 2rem;
  }
}


.border_btm {
  padding-bottom: 0;
  @include breakpoint(medium) {
    padding-bottom: 25px;
  }
  border-bottom: 6px solid;
}


// MediaQueries

@media screen and (max-height: 650px) {
  .main-menu .navi_top .main {
    margin-bottom: 4em;
    padding-top: 2em;
  }
}


@media screen and (max-height: 520px) {
  .main-menu .navi_top .main {
    margin-bottom: 0.5em;
    padding-top: 0.5em;
    .border_btm {
      padding-bottom: 0;
    }
  }
}

@media screen and (max-width: 639px)  and (max-height: 800px) {
  .main-menu .navi_top .main {
    margin-bottom: 4em;
  }
}

@media screen and (max-width: 639px)  and (max-height: 710px) {
  .main-menu .navi_top .main {
    margin-bottom: 2em;
  }

}

@media screen and (max-width: 639px)  and (max-height: 460px) {
  .main-menu .navi_top .main {
    margin-bottom: 1em;
  }
  div#entry_page div#page_content {
    padding-top: 23px!important;
  }
}

@media screen and (max-width: 639px)  and (max-height: 400px) {
  .main-menu .navi_top .main {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 639px)  and (max-height: 350px) {
  .main-menu .navi_top .main h2.heavy {
    display: none;
  }
}

@media screen and (max-width: 639px)  and (max-height: 300px) {
  #logo_entry_page {
    display: none;
  }
}

@media screen and (max-width: 639px)  and (max-height: 250px) {

  .main-menu .navi_top .sub h2 {
    font-size: 0.8rem;
  }

h2.navi_titel  {
    font-size: 1rem;

}

.border_btm {
  border-bottom: none;
}
}

//iPhone 5 & 5S in landscape

@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : landscape) {
  .main-menu .navi_top .sub h2 {
    font-size: 0.8rem;
  }

  h2.navi_titel  {
    font-size: 1rem;

  }

  .border_btm {
    border-bottom: none;
  }


}