.page_darmsanierung {
  @include breakpoint(medium) {
  margin-top: 121px;}
  .vert-center {
    @include breakpoint(small) {
      top: 25px !important;
    }
    @include breakpoint(medium) {
      top: 33px !important;
    }
  }
  .title_text {
    margin-top: 40px!important;
  }


  .image_top {
    background: url("../img/a_beeler_darmsanierung_oben_8318.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: cover;
    background-position: top;
    height: 600px;
  }

  div.pages_content.container {
    padding-bottom: 140px;
  }
}