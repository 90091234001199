#top, #bottom, #left, #right {
  background: white;
  position: fixed;
  z-index: 10;
}

#left, #right {
  top: 0;
  bottom: 0;
  width: 20px;
}

#left {
  left: 0;
}

#right {
  right: 0;
}

#top, #bottom {
  left: 0;
  right: 0;
  height: 20px;
}

#top {
  top: 0;
}

#bottom {
  bottom: 0;
}