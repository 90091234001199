.page_kontakt {
  @include breakpoint(medium) {
    margin-top: 120px;
  }

  div.vert-center {
    h2 {
      font-size: 1.8rem!important;
      @include breakpoint(medium) {
        font-size: 2.5rem!important;
      }
      @include breakpoint(large) {
        font-size: 3rem!important;
      }
      @include breakpoint(xlarge) {
        font-size: 3.5rem!important;
      }
    }
  }

  .image_top {
    background: url("../img/a_beeler_termine_oben.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: cover;
    background-position: center center;
    height: 600px;
  }


}

.formular {
  margin-top: 40px;
  margin-bottom: 120px;
  @include grid-row;
  #form-messages {
    text-align: center;
    margin-bottom: 3%;
  }

  form {
    float: none!important;
    @include grid-column(8);
    @include grid-column-position(center);
    @include breakpoint(medium) {
      @include grid-column(6);
      @include grid-column-position(center);
    }
    @include breakpoint(large) {
      @include grid-column(4);
      @include grid-column-position(center);
    }
  }

}



div#kontakt {
  text-align: center;

  .kontakt_icons {
    margin-bottom: 30px;
    height: 600px;
    @include breakpoint(medium) {
      height: 247px;
      padding-left: 5px;
      padding-right: 5px;
      font-size: 15px;
    }
    @include breakpoint(large) {
      padding-left: 0;
      padding-right: 0;
      font-size: 16px;
    }
    .info-box {
      height: auto;
      padding-top: 15px;
      p {
        padding-top: 15px;
      }
      a {
        padding-top: 15px;
        font-family: Roboto;
      }
      @include grid-column(12);
      @include breakpoint(medium) {
        @include grid-column(3);
      }


      span.icon-info-box {
        img {
          width: 50px;
          height: auto;
          @include breakpoint(xlarge) {
            width: 77px;
          }
        }
      }


    }
  }
  .info-box:last-of-type {
    p {
      margin-bottom: 0;
    }
  }
}


div#standort_anchor {
  padding-top: 3%;
}

div#standort {
  text-align: center;
  h2 {
    font-size: 1.5rem;
  }

  @include grid-row;
  .responsiveContainer {
    margin-top: 40px;
    position: relative;
    padding-bottom: 56%;
    height: 0;
    overflow: hidden;
    margin-bottom: 140px;
  }

  .responsiveContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;

    @include breakpoint(large) {
      padding-left: 0px;
      padding-right: 0px;
    }

  }
}