/**
 * @license
 * MyFonts Webfont Build ID 3156068, 2016-01-19T05:56:37-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: HelveticaNeueLT-Light by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/neue-helvetica/helvetica-45-light/
 * Licensed pageviews: 250,000
 *
 * Webfont: HelveticaNeueLT-Heavy by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/neue-helvetica/helvetica-85-heavy/
 * Licensed pageviews: 500,000
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3156068
 * Webfonts copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 1988 - 2006 Linotype GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype GmbH, and may not be repro
 *
 * © 2016 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/302864");

@font-face {
  font-family: 'HelveticaNeueLT-Light';
  src: url('../fonts/302864_0_0.eot');
  src: url('../fonts/302864_0_0.eot?#iefix') format('embedded-opentype'), url('../fonts/302864_0_0.woff2') format('woff2'), url('../fonts/302864_0_0.woff') format('woff'), url('../fonts/302864_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'HelveticaNeueLT-Heavy';
  src: url('../fonts/302864_1_0.eot');
  src: url('../fonts/302864_1_0.eot?#iefix') format('embedded-opentype'), url('../fonts/302864_1_0.woff2') format('woff2'), url('../fonts/302864_1_0.woff') format('woff'), url('../fonts/302864_1_0.ttf') format('truetype');
}


@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300);


body {
  font-family: 'HelveticaNeueLT-Light';
}

.heavy {
  font-family: 'HelveticaNeueLT-Heavy';
  color: $beeler_brown;
  padding-bottom: 0;
  line-height: 1.2;
  letter-spacing: 1px;
}

.light {
  font-family: 'HelveticaNeueLT-Light';
  color: $beeler_brown;
  padding-bottom: 0;
  line-height: 1.2;
  letter-spacing: 1px;
}


h2, h3, h5, a {
  color: $beeler_brown;
}

a:hover {
  //text-decoration: underline;
  //color: $beeler_schwarz;
  color: $beeler_grey;
}
p, form {

  //color: $beeler_grey;
  font-family: Roboto;

}

p,li {
  color: $beeler_schwarz;
}
h5 {
  letter-spacing: 1px;
}
ul, p, button,a {
  letter-spacing: 1px;
}