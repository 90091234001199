.page_fussreflexzonen_massage {
  margin-top: 121px;
  .vert-center {
    top:85px!important;
    @include breakpoint(medium) {
      top:41px!important;
    }
    @include breakpoint(large) {
      top:32px!important;
    }
    h2 {
      font-size: 1rem!important;
      @include breakpoint(medium) {
        font-size: 1.8rem!important;
      }
      @include breakpoint(large) {
        font-size: 3rem!important;
      }
    }
  }
}

div.fussreflex {
  position: relative;



}

.page_fussreflex {
   .vert-center h2 {
    font-size: 1rem!important;
     @include breakpoint(medium) {
       font-size: 1.8rem!important;
     }
     @include breakpoint(large) {
       font-size: 3rem!important;
     }
  }


  .image_top {
    background: url("../img/a_beeler_fussreflex_oben_8186.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: cover;
    background-position: center center;
    height: 600px;
  }
  div.pages_content.container {
    padding-bottom: 140px;
  }
}