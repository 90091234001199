div#logo {
  position: fixed;
  padding-left: 23px;
  top: 23px;
  @include breakpoint(medium) {
    padding-left: 25px;
    top: 25px;
  }
  z-index:999;
  img {
    width:40px;
    @include breakpoint(medium) {
      width: 65px;
    }

    @include breakpoint(xlarge) {
      //width: 30%;
    }

    //width: 30%;
    height: auto;
    @include breakpoint(xlarge) {
      width: 77px;
    }
  }
}

div#navi {
  position: fixed;
  text-align: right;
  //width: 100%;
  height: auto;
  top: 23px;
  right: 23px;
  @include breakpoint(medium) {
    top: 25px;
    right: 25px;
  }
  z-index: 9;
  img {
    width: 40px;
    height: auto;
    @include breakpoint(medium) {
      width: 65px;
    }
    @include breakpoint(xlarge) {
      width: 77px;
    }
  }
}

div#navi_close {
  z-index: 9999999;
  text-align: right;
  position: absolute;
 // width: 100%;
  height: auto;
  top: 23px;
  right: 23px;
  display: none;
  position: fixed;
  @include breakpoint(medium) {
    top: 25px;
    right: 25px;
  }
  img {
    width: 40px;
    height: auto;
    @include breakpoint(medium) {
      width: 65px;
    }
    @include breakpoint(xlarge) {
      width: 77px;
    }
  }
}
