footer {
  background: rgba(191, 143, 108, 0.85);
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
  .footer_inside {
    text-align: center;
    color: white;
    span.break {
      display: block;
      @include breakpoint(medium) {
        display: inline!important;
      }
    }
    span.break640 {
      display: inline;
      @include breakpoint(medium) {
        display: block;
        @include breakpoint(large) {
          display: inline;
        }
      }
    }
    span.break1024 {
      display: inline;
        @include breakpoint(large) {
          display: block;
        }
      @include breakpoint(xlarge) {
        display: inline;
      }
    }
    p {
      color: white;
      margin-bottom: 0;
    }
    a {
      color: white;
    }
  }
}