div.page_angebot {
div.farb_flaeche{
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 510px;
  background: #71163e;
}




  div.container {

    @include breakpoint(xlarge) {
      padding-left: 0;
      padding-right: 0;

    }
    div.subnavi {
      @include grid-column(12);
      div.info-box {
        @include grid-column(12);
        @include breakpoint(medium) {
          @include grid-column(4);
        }
        text-align: center;
        margin-bottom: 10%;
        //@include grid-column(12);


        .icon-info-box {
          img {
            width: 50px;
            @include breakpoint(medium) {
              width: 65px;
            }
            @include breakpoint(xlarge) {
              width: 77px;
            }

          }
        }
        //@include grid-column(12);
        @include breakpoint(large) {

          @include grid-column(4);

        }

        h2 {
          font-size: 1.5rem;
          @include breakpoint(medium) {
            font-size: 1.1rem;
          }
          @include breakpoint(large) {
            font-size: 1.5rem;
          }


          padding-top: 15px;
        }
        li {
          list-style-type: none;

        }
      }
    }

  }
}