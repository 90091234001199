.page_lymphdrainage {
  @include breakpoint(medium) {
    margin-top: 121px;
  }

  .image_top {
    background: url("../img/a_beeler_lymphdrainage_oben_8121.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: cover;
    background-position: top;
    height: 600px;
  }
  .vert-center {
    @include breakpoint(small) {
      top: 80px !important;
    }
    @include breakpoint(medium) {
      top: 33px !important;
    }
    h2 {
      //font-size: 1.3rem!important;
    }
  }
}
