@charset 'utf-8';
@import 'settings';
@import 'foundation';
@import 'motion-ui';


@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;


//global styles
@import 'typo';
@import 'logo_navi_item';
@import 'navigation';
@import 'page_border';
@import 'footer';

//page specific styles
@import 'index';
@import 'page_angebot';
@import 'page_massagen';
@import 'page_lymphdrainage';
@import 'page_fussreflexzonen_massage';
@import 'page_homoeopathie';
@import 'page_pflanzenheilkunde';
@import 'page_darmsanierung';
@import 'page_ernaehrungsberatung';
@import 'page_ueber_mich';
@import 'page_kontakt';
@import 'page_kosten';




//genaral styles for main_pages
div#main_pages {
  margin-top: 120px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  div.image_top {
    text-align: center;
  }
  @include breakpoint(medium) {
    margin-top: 0%;
  }
  .vert-center {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 71px;
    @include breakpoint(medium) {
      top: 33px;
    }
    //z-index: -9;
    h2 {
      font-size: 2rem;
      color: $beeler_brown;
      padding-bottom: 0;
      margin-bottom: 0;
      @include breakpoint(medium) {
        font-size: 2.5rem;
      }
      @include breakpoint(large) {
        font-size: 3rem;
      }
      @include breakpoint(xlarge) {
        font-size: 3.5rem;
      }
    }
  }
  .container {
    //padding-left: 10px;
    //padding-right: 10px;
    @include grid-row;
    padding-top: 10%;
    @include breakpoint(large) {
      padding-top: 3%;
    }

    .image_bottom {
      margin-bottom: 8%;
    }
  }
}





//general styles for subpages
.sub_pages {
  //margin-top: 88px;
  position: relative;
  width: 100vw;
  @include breakpoint(medium) {
    margin-top: 0%;
  }
  .image_top {
    text-align: center;
  }
  .vert-center {
    text-align: center;
    position: absolute;
    width: 100%;
    max-width: none;
    top: 74px;
    @include breakpoint(medium) {
      top: 33px;
    }
    //z-index: -9;
    h2 {
      color: $beeler_brown;
      padding-bottom: 0;
      margin-bottom: 0;
      font-size: 1.5rem;
      @include breakpoint(medium) {
        font-size: 2.5rem;
      }
      @include breakpoint(large) {
        font-size: 3rem;
      }
      @include breakpoint(xlarge) {
        font-size: 3rem;
      }
      .pink {
        padding-bottom: 25px;
        border-bottom: none;
        @include breakpoint(medium) {
          border-bottom: 9px solid $beeler_brown;
        }

      }

    }
  }


  div.pages_content.container {
    padding-top: 1%;
    @include grid-row;
    @include grid-column(12);
    float: none!important;
    @include breakpoint(xlarge) {
      padding-left: 0%;
      padding-right: 0%;

    }
    .pages_header {
      text-align: center;
        .info-box {
          @include grid-column(12);
          float: none;


          span.icon-info-box {
            img {
              width: 50px;
              height: auto;
              @include breakpoint(xlarge) {
                width: 77px;
              }
            }
          }
          h2.heavy {
            font-size: 1.5rem;
            padding-top: 15px;
          }
        }
      }
    }
    .pages_body {
      div.title_text {
        @include grid-column(12);
        float: none;
        margin-top: 20px;
        margin-bottom: 40px;
        h5 {
          font-size: 1rem;
        }
        p {
          padding-top: 10px;
        }
        ul {
          padding-top: 10px;
          //color: $beeler_schwarz;
          //color: $beeler_grey;
          font-family: Roboto;
        }
      }
      div.title_text:last-of-type {
        margin-top: 0px;
      }
    }
  }
  .image_bottom {
    margin-bottom: 8%;
    text-align: center;
    @include grid-column(12);
    padding-left: 0px!important;
    padding-right: 0px!important;
  }

.toggle_content p {
  margin-bottom: 0px;
}

#back-to-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  //z-index: 9999;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  opacity: 0;
}
#back-to-top:hover {
 img {
   opacity: 0.8;
   filter: alpha(opacity=80); /* For IE8 and earlier */
 }
}
#back-to-top.show {
  opacity: 1;
}