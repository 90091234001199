.page_homoeopathie {
  .image_top {
    background: url("../img/a_beeler_homoeopathie_oben_8368.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: cover;
    background-position: center center;
    height: 600px;
  }

  div.pages_content.container {
    padding-bottom: 140px;
  }
}