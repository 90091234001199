body.home {
  background: #f2f2f2;
}


//main page
div#entry_page {
  width: 100vw;
  height: 100vh;
  background: #f2f2f2;
  div#page_content {
    padding-top: 120px;
    margin-left: 3em;
    @include breakpoint(xlarge) {
      margin-left: 6em;
      //margin-top: 2em;
    }
    div#logo_entry_page {
      width: 100%;
      height: auto;
      img {
        width: 114px;
        height: auto;
        @include breakpoint(medium) {
          width: 128px;
        }
        @include breakpoint(large) {
          width: 180px;
        }
      }
    }
    div#main_title {
      padding-top: 20px;
      padding-right: 20px;
      @include breakpoint(medium) {

      }
      @include breakpoint(large) {
        padding-top: 30px;
      }
      h1 {
        font-size: 1.5rem;
        @include breakpoint(medium) {
          font-size: 2.5rem;
        }
        @include breakpoint(xlarge) {
          font-size: 2.7rem;
        }
      }
    }
  }
}