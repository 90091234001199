.page_pflanzenheilkunde {
  .vert-center {
    @include breakpoint(small) {
      top: 80px !important;
    }
    @include breakpoint(medium) {
      top: 33px !important;
    }
    h2 {
      font-size: 1rem!important;
      @include breakpoint(medium) {
        font-size: 2rem!important;
      }
      @include breakpoint(large) {
        font-size: 3rem!important;
      }
    }
  }

  .image_top {
    background: url("../img/a_beeler_pflanzenheilkunde_oben_8339.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: cover;
    background-position: center center;
    height: 600px;
  }

  .title_text {
    margin-top: 20px!important;
  }


  div.pages_content.container {
  @include breakpoint(medium) {
    padding-bottom: 140px;}
  }
}