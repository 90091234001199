.page_ernaehrungsberatung {
  @include breakpoint(medium) {
  margin-top: 121px; }
  .vert-center {
    top:25px!important;
    @include breakpoint(medium) {
      top:41px!important;
    }
    @include breakpoint(large) {
      top:32px!important;
    }
    h2 {
      font-size: 1.2rem!important;
      @include breakpoint(medium) {
        font-size: 1.8rem!important;
      }
      @include breakpoint(large) {
        font-size: 3rem!important;
      }
    }
  }
  .title_text {
    margin-top: 40px!important;
  }

  .image_top {
    background: url("../img/a_beeler_ernaehrung_oben_8294.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: cover;
    background-position: top;
    height: 600px;
  }

  div.pages_content.container {
   @include breakpoint(medium) {
    padding-bottom: 140px;}
  }
}