.page_massage {
  //margin-top: 121px;
  .image_top {
    background: url("../img/a_beeler_massagen_oben_8065.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: cover;
    background-position: center center;
    height: 600px;

  }
}

.massage {
  overflow: hidden;
}