.page_ueber_mich {
  @include breakpoint(medium) {
    margin-top: 121px;
  }

  .title_text {
    margin-top: 40px!important;
  }
  .image_top {
    background: url("../img/a_beeler_uebermich_8212.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: cover;
    background-position: 75%;
    @include breakpoint(medium) {
      background-position: top;
    }

    height: 600px;
  }

  div.pages_content.container {
    padding-bottom: 140px;
  }
}